import React from 'react'

import { useAuthContext } from '../../hooks/useAuthContext'
import { Link } from 'react-router-dom'

//components

//styles
import './Home.css'

export default function Home() {
  const { user } = useAuthContext()

  return (
    <div className='hcontainer'>
      <div className='hcontent'>
        <p>
          <span className='subtitle'>Hello </span>
          <span className='title'> {user.displayName},</span>
        </p>
        <h3 className='bajada'>thank you for visiting my portfolio!</h3>
        <div className='btn-container'>
          <Link to={'../project/webprojects'}>
            <button className='btn web' to={'../project/webprojects'}>
              See my web design work
            </button>
          </Link>
          <Link to={'../project/Project'}>
            <button className='btn dev' to={'../project/Project'}>
              See my dev work
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
