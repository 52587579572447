import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/ch.svg'
import { useAuthContext } from '../hooks/useAuthContext'

//styles
import './Footer.css'

export default function Footer() {
  const { user } = useAuthContext()

  return (
    <>
      <div className='fcontainer'>
        <nav className='footer'>
          <Link className='logo' to='/'>
            <img className='flogo' src={logo} alt='logo icon' />
          </Link>
          <p className='contactData'>
            <span className='chuchos'>Phone:</span>{' '}
            <a href='tel:+33778828647'>+33778828647</a> <br />
            <span className='chuchos'>Mail:</span>{' '}
            <a href='mailto:lucas@chucho.design'>lucas@chucho.design</a>
            <br />
            <span className='chuchos'>Skype:</span> lezubeldia
          </p>
          <p className='copyright'>
            <span className='chuchos'>LOS CHUCHOS</span>
            <br />
            all rights reserved <br />© 2023
          </p>
        </nav>
      </div>
    </>
  )
}
