import React from 'react'
import { useState } from 'react'
import contact from '../../assets/contact.svg'
import { projectFirestore } from '../../firebase/config'

// style
import './Contact.css'

export default function HireMe() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    projectFirestore
      .collection('NEW MESSAGE')
      .add({
        name: name,
        email: email,
        message: message,
      })
      .then(() => {
        alert('message has been submitted')
      })
      .catch((err) => {
        alert(err.message)
      })
    setName('')
    setEmail('')
    setMessage('')
  }

  return (
    <div className='ccontent'>
      <div className='textcontent'>
        <form className='contact-form' onSubmit={handleSubmit}>
          <p className='title'>Contact me</p>
          <p className='description'>
            Just fill out the form below or email me <br />
            here: <a href='mailto:lucas@chucho.design'>lucas@chucho.design</a>
          </p>
          <label>
            <span>Name:</span>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </label>

          <label>
            <span>Email:</span>
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>

          <label>
            <span>Message:</span>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>

          <button className='btn' target='_blank' typeof='submit'>
            Submit
          </button>
        </form>
      </div>
      <img
        className='contactimg'
        src={contact}
        alt='an illustration for send a message'
      />
    </div>
  )
}
