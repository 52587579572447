import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { projectFirestore } from '../../firebase/config'

//styles
import './ProjectDetail.css'
// import Buttons from '../../components/Buttons'

export default function ProjectDetail({}) {
  const { id } = useParams()
  const [project, setProject] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState()

  useEffect(() => {
    setIsPending(true)
    projectFirestore
      .collection('projects')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIsPending(false)
          setProject(doc.data())
        } else {
          setIsPending(false)
          setError('could not find that project')
        }
      })
  }, [])

  useEffect(() => {
    setIsPending(true)
    projectFirestore
      .collection('projects')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setError('No projects to load')``
          setIsPending(false)
        } else {
          let results = []
          snapshot.docs.forEach((doc) => {
            //el data de doc.data() es aparte del useState
            results.push({ id: doc.id, ...doc.data() })
          })
          setData(results)
          setIsPending(false)
        }
      })
      .catch((err) => {
        setError(err.message)
        setIsPending(false)
      })
  }, [])
  return (
    <div className='dcontainer'>
      {error && <p className='error'>{error}</p>}
      {isPending && <p className='loading'>Loading...</p>}
      {project && (
        <>
          <div className='ProjectDetail'>
            <h1 className='title'>{project.title}</h1>
          </div>
          <div className='ContentDetail'>
            <ul className='tech'>
              {project.technologies.map((tech, index) => (
                <li key={index} id={(index, tech)}>
                  {tech}
                </li>
              ))}
            </ul>
            <div className='data'>
              <p className='description'>{project.description}</p>
              <button className='btn'>
                <Link to={project.live_link} target='_blank'>
                  Live Demo
                </Link>{' '}
              </button>
            </div>
            <img className='imgl' src={project.image_url[1]} alt='thumbnail' />
            <img className='imgm' src={project.image_url[2]} alt='thumbnail' />
            <img className='imgs' src={project.image_url[3]} alt='thumbnail' />
            {/* <Buttons project={project} /> */}
          </div>{' '}
        </>
      )}
    </div>
  )
}
