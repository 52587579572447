import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
  //cleanup function
  const [isCancelled, setIsCancelled] = useState(false)
  // error
  const [error, setError] = useState(null)
  // loading
  const [isPending, setIsPending] = useState(false)
  // dispatch
  const { dispatch } = useAuthContext()

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)
    try {
      // login user
      const res = await projectAuth.signInWithEmailAndPassword(email, password)
      // dispatch logout action
      dispatch({ type: 'LOGIN', payload: res.user })

      //update state
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } catch (err) {
      if (!isCancelled) {
        console.log(err)
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])
  return { error, isPending, login }
}
