import React from 'react'
import { Link } from 'react-router-dom'

// styles
import './WebProjectList.css'

export default function WebProjectList({ projects }) {
  return (
    <div className='plcontainer'>
      <div className='plcontent'>
        <p className='text'>
          <span className='title'> Web Design Portfolio</span>
          <br />
          <span className='bajada'>
            As a human being we need to eat, these are the works that provides a
            dish of food on the table every month.{' '}
          </span>
        </p>

        {projects.map((webproject) => (
          <div key={webproject.id} className='card'>
            <ul className='tech'>
              {webproject.technologies.map((tech, index) => (
                <li className='stech' key={index} id={index}>
                  {tech}
                </li>
              ))}
            </ul>
            <h3>{webproject.title} </h3>
            <img src={webproject.image_url[0]} alt='thumbnail' />
            <p>{webproject.description.substring(0, 60)}...</p>
            <Link className='link_intern' to={`/webprojects/${webproject.id}`}>
              View Project {String.fromCharCode(62)}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
