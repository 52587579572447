import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
// import { useFetch } from './hooks/useFetch'

//page components
import Home from './pages/home/Home'
import About from './pages/about/About'
import Project from './pages/project/Project'
import WebProjects from './pages/webprojects/WebProjects'
import WebProjectDetail from './pages/webprojectdetail/WebProjectDetail'
import ProjectDetail from './pages/projectdetail/ProjectDetail'
import Contact from './pages/contact/Contact'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

function App() {
  const { authIsReady, user } = useAuthContext()
  // const { data } = useFetch('http://localhost:3000/projects')

  return (
    <div className='App'>
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              exact
              path='/'
              element={user ? <Home /> : <Navigate to='login' />}
            />

            <Route
              path='/about'
              element={user ? <About /> : <Navigate to='/' />}
            />

            <Route
              path='/project/webprojects'
              element={user ? <WebProjects /> : <Navigate to='/' />}
            />

            <Route
              path='/project/:id'
              element={user ? <WebProjects /> : <Navigate to='/' />}
            />

            <Route
              path='/project/project'
              element={user ? <Project /> : <Navigate to='/' />}
            />

            <Route
              path='/project/:id'
              element={user ? <Project /> : <Navigate to='/' />}
            />
            <Route
              path='projects/:id'
              element={user ? <ProjectDetail /> : <Navigate to='/' />}
            />

            <Route
              path='webprojects/:id'
              element={user ? <WebProjectDetail /> : <Navigate to='/' />}
            />
            <Route path='/contact' element={<Contact />} />

            <Route
              path='/login'
              element={!user ? <Login /> : <Navigate to='/' />}
            />
            <Route
              path='/signup'
              element={!user ? <Signup /> : <Navigate to='/' />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
