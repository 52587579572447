import React from 'react'
import { Link } from 'react-router-dom'
import profile from '../../assets/profile.jpg'
import browser from '../../assets/browser.svg'
import code from '../../assets/code.svg'
import css from '../../assets/css.svg'
import html from '../../assets/html.svg'
import js from '../../assets/js.svg'
import pencil from '../../assets/pencil.svg'
import react from '../../assets/react.svg'
// style
import './About.css'
const url = 'https://www.linkedin.com/in/lucaszubeldia/?locale=en_US'
export default function About() {
  return (
    <div className='acontainer'>
      <div className='acontent'>
        <p className='atext'>
          <span className='title'>About me</span>
          <br />
          <p className='bajada'>
            I love Chuchos and I hope one day{' '}
            <span className='clog'>&#123;console.log&#125; </span>
            will be a real life feature
          </p>
        </p>
        <p className='description'>
          Hello, my name is Lucas, i'm a graphic designer from Buenos Aires. I
          have been working as a Web and Digital Designer since 2006 for
          different agencies and clients. I have more than 17 years of
          experience designing websites, logos and apps. I am an expert in Adobe
          Photoshop, Illustrator, After effects, Sketch and i can write some
          HTML & CSS line codes. I love to do stuff with React. <br />
        </p>
        <button className='btn_secundary'>
          <Link to={url} target='_blank'>
            View My Resume
          </Link>
        </button>
        <img className='aprofile' src={profile} alt='an imagen of myself' />
        <div className='servicesContainer'>
          <h3 className='tag'>Services</h3>

          <div className='stack'>
            <p className='techstack'>
              <p className='ssubtitle'>Software Development</p>
              <span>—</span>
              <p className='description'>
                We can develop web solutions of any type and intricacy, from a
                single-page application to a multi-functional business
                management system.
              </p>
            </p>
            <p className='techstack'>
              <p className='ssubtitle'>Logotype Design</p>
              <span>—</span>
              <p className='description'>
                Grow your business with a professional, custom logo designed by
                our creative experts. Because good design makes great business.
              </p>
            </p>
            <p className='techstack'>
              <p className='ssubtitle'>Website Design</p>
              <span>—</span>
              <p className='description'>
                Launch a website that captures your brand, improves your
                conversion rates, and maximizes your revenue with Chucho.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
