import { useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  // error
  const [error, setError] = useState(null)
  // loading
  const [isPending, setIsPending] = useState(false)
  // dispatch
  const { dispatch } = useAuthContext()

  const logout = async () => {
    setError(null)
    setIsPending(true)
    try {
      // logout user
      await projectAuth.signOut()
      // dispatch logout action
      dispatch({ type: 'LOGOUT' })
      setIsPending(false)
      setError(null)
    } catch (err) {
      console.log(err)
      setError(err.message)
      setIsPending(false)
    }
  }
  return { error, isPending, logout }
}
