import React from 'react'
import Library from '../components/Library'

import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import logo from '../assets/ch.svg'
import { useAuthContext } from '../hooks/useAuthContext'
import burg from '../assets/burg.svg'
import { useState } from 'react'

//styles
import './Navbar.css'

export default function Navbar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const [libraryStatus, setLibraryStatus] = useState(false)

  return (
    <div className='container'>
      <nav className='navbar'>
        <Link className='logo' to='/'>
          <img className='logotype' src={logo} alt='logo icon' />
        </Link>
        <ul>
          {!user && (
            <>
              <li className='menu'>
                <Link className='login' to='/login'>
                  Login
                </Link>
              </li>
              <li className='menu'>
                <Link to='/signup'>Sign up</Link>
              </li>
              <li className='menu'>
                <Link to='/contact'>Contact me</Link>
              </li>
              <img
                className='bicon'
                src={burg}
                onClick={() => setLibraryStatus(!libraryStatus)}
                alt='menu svg icon'
              />
            </>
          )}
          {user && (
            <>
              <li className='menu'>
                <Link to='/about'>About</Link>
              </li>
              <li className='menu'>
                <Link to='/project/webprojects'>Web Portfolio</Link>
              </li>
              <li className='menu'>
                <Link to='/project/project'>Chucho's Lab</Link>
              </li>
              <li className='menu'>
                <Link to='/contact'>Contact Me</Link>
              </li>
              <li className='btn_logout' onClick={logout}>
                Logout
              </li>
              <img
                className='bicon'
                src={burg}
                onClick={() => setLibraryStatus(!libraryStatus)}
                alt='menu svg icon'
              />
            </>
          )}
        </ul>
        <Library libraryStatus={libraryStatus} />
      </nav>
    </div>
  )
}
