import React from 'react'

import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import logo from '../assets/logo-mob.svg'
import { useAuthContext } from '../hooks/useAuthContext'
import './SideMenu.css'

export default function SideMenu({ libraryStatus }) {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  return (
    <div className='container-mob'>
      <nav className='navbar-mob'>
        <ul>
          {!user && (
            <>
              <li>
                <img className='logo-mob menu-mob' src={logo} alt='logo icon' />
              </li>
              <li className='menu-mob'>
                <Link className='login menu-mob' to='/login'>
                  Login
                </Link>
              </li>
              <li className='menu-mob'>
                <Link className='menu-mob' to='/signup'>
                  Sign up
                </Link>
              </li>
              <li className='menu-mob'>
                <Link className='menu-mob' to='/contact'>
                  Contact me
                </Link>
              </li>
            </>
          )}
          {user && (
            <>
              <li>
                <img className='logo-mob menu-mob' src={logo} alt='logo icon' />
              </li>
              <li className='btn_logout-mob menu-mob' onClick={logout}>
                Logout
              </li>
              <li className='menu-mob'>
                <Link className='menu-mob' to='/Services'>
                  Services
                </Link>
              </li>

              <li className='menu-mob'>
                <Link className='menu-mob' to='/about'>
                  About me
                </Link>
              </li>

              <li className='menu-mob'>
                <Link className='menu-mob' to='/project/project'>
                  Chucho's Lab
                </Link>
              </li>
              <li className='menu-mob'>
                <Link className='menu-mob' to='/contact'>
                  Contact Chucho
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  )
}
