import React from 'react'
import LibraryProject from './LibraryProject'
import './Library.css'
import SideMenu from './SideMenu'

export default function Library({ libraryStatus }) {
  return (
    <div className={`library ${libraryStatus ? 'active-library' : ''}`}>
      {/* <div>
        {projects.map((project) => (
          <LibraryProject project={project} />
        ))}

      </div> */}
      <SideMenu libraryStatus={libraryStatus} />
    </div>
  )
}
