import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { projectFirestore } from '../../firebase/config'

//styles
import './WebProjectDetail.css'
// import Buttons from '../../components/Buttons'

export default function WebProjectDetail({}) {
  const { id } = useParams()
  const [project, setProject] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setIsPending(true)
    projectFirestore
      .collection('webprojects')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setIsPending(false)
          setProject(doc.data())
        } else {
          setIsPending(false)
          setError('could not find that project')
        }
      })
  }, [])

  return (
    <div className='dcontainer'>
      {error && <p className='error'>{error}</p>}
      {isPending && <p className='loading'>Loading...</p>}
      {project && (
        <>
          <div className='ProjectDetail'>
            <h1 className='title'>{project.title}</h1>
          </div>
          <div className='ContentDetail'>
            <ul className='tech'>
              {project.technologies.map((tech, index) => (
                <li key={index} id={(index, tech)}>
                  {tech}
                </li>
              ))}
            </ul>
            <div className='data'>
              <p className='description'>{project.description}</p>
              <button className='btn'>
                <Link to={project.behance_link} target='_blank'>
                  Behance Link
                </Link>
              </button>
            </div>
            <img className='wimgl' src={project.image_url[1]} alt='thumbnail' />
            <img className='wimgs' src={project.image_url[2]} alt='thumbnail' />
            <img
              className='wimgxl'
              src={project.image_url[4]}
              alt='thumbnail'
            />
            <img className='wimgm' src={project.image_url[3]} alt='thumbnail' />
            <img className='wimgc' src={project.image_url[5]} alt='thumbnail' />

            {/* <Buttons project={project} /> */}
          </div>
        </>
      )}
    </div>
  )
}
