import React from 'react'
import { Link } from 'react-router-dom'

// styles
import './ProjectList.css'

export default function ProjectList({ projects }) {
  return (
    <div className='plcontainer'>
      <div className='plcontent'>
        <p className='text'>
          <span className='title'> Chuchos Lab</span>
          <br />
          <span className='bajada'>
            This is the place where I play with different languages.
          </span>
        </p>

        {projects.map((project) => (
          <div key={project.id} className='card'>
            <ul className='tech'>
              {project.technologies.map((tech, index) => (
                <li className='stech' key={index} id={index}>
                  {tech}
                </li>
              ))}
            </ul>
            <h3>{project.title} </h3>
            <img src={project.image_url[0]} alt='thumbnail' />
            <p>{project.description.substring(0, 60)}...</p>
            <Link className='link_intern' to={`/projects/${project.id}`}>
              View Project {String.fromCharCode(62)}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
