import React from 'react'
import { projectFirestore } from '../../firebase/config'
import { useEffect, useState } from 'react'
//components
import WebProjectList from '../../components/WebProjectList'
//styles
import './WebProjects.css'
export default function WebProjects() {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setIsPending(true)
    projectFirestore
      .collection('webprojects')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setError('No projects to load')
          setIsPending(false)
        } else {
          let results = []
          snapshot.docs.forEach((doc) => {
            //el data de doc.data() es aparte del useState
            results.push({ id: doc.id, ...doc.data() })
            console.log(doc)
          })
          setData(results)
          setIsPending(false)
        }
      })
      .catch((err) => {
        setError(err.message)
        setIsPending(false)
      })
  }, [])

  return (
    <>
      {error && <p className='error'>{error}</p>}
      {isPending && <p className='loading'>Loading...</p>}
      {data && <WebProjectList projects={data} />}
    </>
  )
}
