import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDThEYuZTp1apVYCS9n3Zz6RYSsUDIMn_8',
  authDomain: 'moncho-118f2.firebaseapp.com',
  projectId: 'moncho-118f2',
  storageBucket: 'moncho-118f2.appspot.com',
  messagingSenderId: '409192614965',
  appId: '1:409192614965:web:ef5c17fc85aa6f1b78757c',
}
// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// database contact

export { projectFirestore, projectAuth }
